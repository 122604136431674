<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="35"
    width="35"
    viewBox="0 0 1200 1200"
  >
    <path
      fill="currentColor"
      d="M 503.2,862.4 C 573.4,862.4 643.7,862.4 714.0,862.4 711.8,903.8 668.1,954.1 603.8,951.5 550.8,949.2 501.4,904.7 503.2,862.4 Z"
    />
    <g>
      <path
        fill="currentColor"
        d="M 5.8,603.4 C 7.0,326.9 200.8,88.7 471.2,31.1 662.1,-13.9 862.8,40.1 1005.4,174.7 1109.4,266.5 1175.2,394.0 1189.8,532.0 1213.3,705.4 1155.5,879.9 1033.1,1005.0 941.4,1104.5 817.1,1168.1 682.7,1184.2 502.1,1211.5 319.2,1152.7 188.4,1025.2 89.7,933.5 26.8,809.4 11.2,675.4 7.9,651.6 7.5,627.4 5.8,603.4 Z M 66.5,634.5 C 72.2,763.6 127.7,885.5 221.3,974.6 431.5,1183.0 770.4,1182.6 980.1,973.8 1093.3,864.6 1149.4,709.0 1131.9,552.7 1122.1,430.0 1067.3,315.3 978.0,230.6 853.4,104.7 673.7,50.0 500.1,85.4 251.0,128.9 66.5,350.2 66.5,634.5 L 66.5,634.5 Z"
      />
      <path
        fill="currentColor"
        d="M 432.076,891.123 L 242.676,1001.223 C 336.576,1098.123 466.276,1152.023 601.176,1150.223 735.476,1152.123 864.476,1098.123 957.476,1001.223 L 753.376,893.923 C 729.876,882.223 715.076,858.223 715.076,831.923 L 715.076,760.723 C 721.176,753.723 726.976,746.323 732.276,738.623 756.776,703.823 775.776,665.523 788.676,624.923 812.676,617.723 829.076,595.723 829.276,570.723 L 829.276,494.823 C 829.276,478.723 822.276,463.323 810.176,452.623 L 810.176,342.823 C 810.176,342.823 832.776,171.923 601.176,171.923 369.576,171.923 392.176,342.823 392.176,342.823 L 392.176,452.623 C 380.076,463.323 373.176,478.723 373.076,494.823 L 373.076,570.823 C 373.176,590.223 383.076,608.223 399.376,618.623 411.976,670.323 435.476,718.823 468.176,760.823 L 468.176,830.323 C 468.076,855.723 454.276,878.923 432.076,891.123 L 432.076,891.123 Z"
      />
    </g>
  </svg>
</template>
